// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  BASE_API_URL: 'https://marketing-made-easy.dedicateddevelopers.us/api/',
  BASE_API_URL_WP: 'https://dedicateddevelopers.us/mme-wp/wp-json/',
  // IMAGE_BASE_URL: 'https://marketing-made-easy.dedicateddevelopers.us/uploads/',
  IMAGE_BASE_URL: 'https://marketing-made-easy.dedicateddevelopers.us/storage/',
  production: false,
  reCaptchaSiteKey: '6LdyRm4bAAAAAKVtDwDQyQ-fc85zyvg81HArKHsp',
  reCaptchaSecretKey: '6LdyRm4bAAAAAOavf7oVS22Lp_duiTA4YjbLXf1T'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
