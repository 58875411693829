import { EventService } from './event.service';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@envconfig';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StorageService } from './storage.service';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})


export class ApiService {


  // upsBaseUrl = 'https://wwwcie.ups.com/';
  // fromAddress = {
  //   "AddressLine" : "301, Ridgestone court piedmont, SC",
  //   "City" : "PIEDMONT",
  //   "PostalCode": "29673",
  //   "StateProvinceCode" : "SC",
  //   "CountryCode" : "US"
  // }
  // upsSecret = {
  //   'Content-Type': 'application/json',
  //   Accept: '*/*',
  //   transId:'UPs65787',
  //   transactionSrc:'web',
  //   AccessLicenseNumber:'3DA472266BCEB3B2',
  //   Username:'firstpp1',
  //   Password:'fg24h9RTJ@#%^',
  //   'User-Agent' : "PostmanRuntime/7.26.8"


  //   // "Host": "wwwcie.ups.com",
  //   // "User-Agent": "PostmanRuntime/7.26.8",
  //   // "Accept": "*/*",
  //   // "Accept-Language": "en-US,en;q=0.5",
  //   // "Accept-Encoding": "gzip, deflate, br",
  //   // "Content-Type": "application/json",
  //   // "transId": "UPs65787",
  //   // "transactionSrc": "web",
  //   // "AccessLicenseNumber": "3DA472266BCEB3B2",
  //   // "Username": "firstpp1",
  //   // "Password": "fg24h9RTJ@#%^",
  //   // "Connection": "keep-alive",
  //   // "Origin": "http://localhost:4202",
  //   // "Sec-Fetch-Dest": "empty",
  //   // "Sec-Fetch-Mode": "no-cors",
  //   // "Sec-Fetch-Site": "cross-site",
  //   // "Pragma": "no-cache",
  //   // "Cache-Control": "no-cache",
  //   // "Content-Length": "332",
  // }

  constructor(
    private http: HttpClient,
    private event: EventService,
    private storage: StorageService
  ) {

    this.event.isLogin.subscribe((isLogin: boolean) => {
      if (isLogin) {
        this.TOKEN = this.storage.getDataField('token');
        this.setHeader(this.TOKEN);
      } else {
        this.setHeader('false');
      }
    });
  }

  private BASE_API_URL = environment.BASE_API_URL;
  private BASE_API_URL_WP = environment.BASE_API_URL_WP;
  httpOptions: { headers: HttpHeaders; };
  // httpOptionsUps: { headers: HttpHeaders; };
  TOKEN: string;
  async setHeader(TOKEN: string): Promise<any> {
    if (TOKEN !== 'false') {
      // console.log('Bearer', TOKEN);
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
          // 'x-access-token': TOKEN
          Authorization: `Bearer ${TOKEN}`
        })
      };
      // console.log('httpOptions', this.httpOptions);
    } else {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'multipart/form-data',
        })
      };
    }
  }



  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.BASE_API_URL}${path}`, { headers: this.httpOptions.headers, params })
      .pipe(catchError(this.formatErrors));
  }

  getWp(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(`${this.BASE_API_URL_WP}${path}`, { headers: this.httpOptions.headers, params })
      .pipe(catchError(this.formatErrors));
  }

  post(path: any, body: object = {}, reportProgress = false): Observable<any> {
    return this.http.post(`${this.BASE_API_URL}${path}`, body, { headers: this.httpOptions.headers, reportProgress, })
      .pipe(catchError(this.formatErrors));
  }
  // POST with load
  // tslint:disable-next-line:typedef
  postWithLoader(path: any, body: object = {}, loader: boolean = true, reportProgress = false) {
    // this.event.loading.next(loader);
    this.event.setLoaderEmmit(true);
    return this.http.post(`${this.BASE_API_URL}${path}`, body, { headers: this.httpOptions.headers, reportProgress, })
      .pipe(catchError(this.formatErrors));
  }
  postMultiData(path: string, file: FormData, reportProgress = false): Observable<any> {
    const httpOptionsimg = {
      headers: new HttpHeaders({
        Accept: 'multipart/form-data',
        // 'x-access-token': this.TOKEN
        Authorization: `Bearer ${this.TOKEN}`
      }),
      reportProgress
    };
    return this.http.post(`${this.BASE_API_URL}${path}`, file, httpOptionsimg)
      .pipe(catchError(this.formatErrors));
  }
  alert(message: string, type: any, duraion?: number): any {
    return Swal.fire({
      title: message,
      icon: type,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: duraion ? duraion : 5000,
    });
  }

  alertModal(message: string, type: any, CancelButton = false, isIcon?: boolean): any {
    return Swal.fire({
      text: message,
      icon: isIcon ? type : null,
      showConfirmButton: true,
      showCancelButton: CancelButton,
      confirmButtonText: 'Ok'
    });
  }

  private formatErrors(error: any): any {
    return throwError(error.error);
  }

  /*getAddressValidity(userAddress: any): Observable<any>  {
    // const httpOptionsUps = {
    //   headers: new HttpHeaders(this.upsSecret)
    // };

    this.httpOptionsUps = {
      headers: new HttpHeaders(this.upsSecret)
    };

    // this.httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     Accept: 'multipart/form-data',
    //   })
    // };

    // sample address
    // {
    //     "ConsigneeName": "RITZ CAMERA CENTERS-1749",
    //     "BuildingName": "Innoplex",
    //     "AddressLine": [
    //         "26601 ALISO CREEK ROAD",
    //         "STE D",
    //         "ALISO VIEJO TOWN CENTER"
    //     ],
    //     "Region": "ROSWELL,GA,30076-1521",
    //     "PoliticalDivision2": "ALISO VIEJO",
    //     "PoliticalDivision1": "CA",
    //     "PostcodePrimaryLow": "92656",
    //     "PostcodeExtendedLow": "1521",
    //     "Urbanization": "porto arundal",
    //     "CountryCode": "US"
    // }
    //sample address
    const reqData = {
      "XAVRequest": {
          "AddressKeyFormat": userAddress
      }
    };
  //   const reqData = {
  //     "AccessRequest": {
  //         "AccessLicenseNumber": "3DA472266BCEB3B2",
  //         "UserId": "firstpp1",
  //         "Password": "fg24h9RTJ@#%^"
  //     },
  //     "AddressValidationRequest":{
  //         "Request": {
  //             "TransactionReference": {
  //                 "CustomerContext": "Your Customer Context"
  //             },
  //             "RequestAction": "AV"
  //         },
  //         "Address": {
  //             "City": "DELHI",
  //             "StateProvinceCode": "DL",
  //             "PostalCode": "600091",
  //             "Country": "IN"
  //         }
  //     }
  // };
    return this.http.post( this.upsBaseUrl + 'addressvalidation/v1/1', reqData, { headers: this.httpOptionsUps.headers })
      .pipe(catchError(this.formatErrors));

  }*/

  // getShipmentCharge(userAddress: string): Observable<any>  {
  //   const httpOptionsimg = {
  //     headers: new HttpHeaders(this.upsSecret)
  //   };
  //   return this.http.post( this.upsBaseUrl + 'rating/Rate', userAddress, { headers: this.upsSecret })
  //     .pipe(catchError(this.formatErrors));

  // }
}
