<ng-container *ngIf="dialogData.type === 'printMoreCount' && !isPayment">
  <div class="p-3 text-center   ">

    <div class="product-des">
      <div class="row mx-0">
        <div class="col-lg-12">
          <div class="price-tabl">
            <table class="table table-borderless mb-2 price-table">
              <thead>
                <!-- <th>Select Box</th> -->
                <th></th>
                <th>Material</th>
                <th>Size</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Price</th>
              </thead>
              <tbody>
                <tr *ngFor="let price of productDetails?.product_price; let i =index">
                  <td>
                    <input style="width: 20px; height: 20px;" type="checkbox"
                      (change)="priceId = priceId === price.id ? '' : price.id; onChangeProduct(price,$event)"
                      [checked]="priceId === price.id">

                  </td>
                  <td>
                    <p class="title">{{price?.material}}</p>

                  </td>
                  <td>
                    <p class="title">{{price?.size}}</p>
                  </td>
                  <td>
                    <p class="title">{{price?.pattern}}</p>
                  </td>
                  <td>
                    <p class="title">{{price?.quantity}}</p>
                  </td>
                  <td>

                    <p class="desc">${{price?.price}}</p>
                  </td>
                </tr>
                <!-- <tr>
                  <td><input style="width: 20px; height: 20px;" type="checkbox" /></td>
                  <td>
                    <p class="title">PVC-1</p>
                  </td>
                  <td>
                    <p class="title">120"X250"</p>
                  </td>
                  <td>
                    <p class="title">EDGED CORNER WITH REBAIT HOLES</p>
                  </td>
                  <td>
                    <p class="title">1</p>
                  </td>
                  <td>
                    <p class="title">$10000</p>
                  </td>
                </tr>
                <tr>
                  <td><input style="width: 20px; height: 20px;" type="checkbox" /></td>
                  <td>
                    <p class="title">PVC-1</p>
                  </td>
                  <td>
                    <p class="title">120"X250"</p>
                  </td>
                  <td>
                    <p class="title">EDGED CORNER WITH REBAIT HOLES</p>
                  </td>
                  <td>
                    <p class="title">1</p>
                  </td>
                  <td>
                    <p class="title">$10000</p>
                  </td>
                </tr>
                <tr>
                  <td><input style="width: 20px; height: 20px;" type="checkbox" /></td>
                  <td>
                    <p class="title">PVC-1</p>
                  </td>
                  <td>
                    <p class="title">120"X250"</p>
                  </td>
                  <td>
                    <p class="title">EDGED CORNER WITH REBAIT HOLES</p>
                  </td>
                  <td>
                    <p class="title">1</p>
                  </td>
                  <td>
                    <p class="title">$10000</p>
                  </td>
                </tr>
                <tr>
                  <td><input style="width: 20px; height: 20px;" type="checkbox" /></td>
                  <td>
                    <p class="title">PVC-1</p>
                  </td>
                  <td>
                    <p class="title">120"X250"</p>
                  </td>
                  <td>
                    <p class="title">EDGED CORNER WITH REBAIT HOLES</p>
                  </td>
                  <td>
                    <p class="title">1</p>
                  </td>
                  <td>
                    <p class="title">$10000</p>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>

          <!-- <div class="d-flex flex-wrap">
            <button class="btn btn-c btn-primary mb-5 mt-md-4 mt-2">Order Now</button>
          </div> -->
          <hr />
          <div class="row align-items-end">
            <!-- <div class="col-md-12 ">
              <h1 class="text-left classpnt">Print Count</h1>
            </div> -->
            <!-- <div class="col-md-4 text-left">

              <input type="number" class="form-control mb-2" min="1" (change)="qty($event)">
              <label for="" class="text-danger"> Price : ${{totalVal?totalVal:0}}</label>
            </div> -->
            <p class="text-danger">Price : ${{productPrice ?productPrice:0 }}</p>
            <div class="col-md-2" (click)="print()">
              <button class="btn btn-c btn-primary d-block w-100">Print</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <h1 class="text-center">Print Count</h1>
    <label for="" style="color: red;">Unit Price : ${{dialogData?.price}}</label><br>
    <input type="number" placeholder="Count" [(ngModel)]="dialogData.count">
    <input type="submit" value="Print" [disabled]="!dialogData.count" class="text-white btn btn-red"
      (click)="submitPrint()"> -->
  </div>
</ng-container>

<ng-container *ngIf="dialogData.type === 'artWork'">
  <div class="artWorksec">
    <div class="p-0 text-center">
      <h3 class="text-center">Art Work</h3>
      <figure class="pr-acc-info w-100" *ngIf="dialogData?.order?.file_type === 'image'">
        <img class="d-block w-100"
          [src]="dialogData?.order?.artwork_full_path ? dialogData?.order?.artwork_full_path : './assets/images/no-image.png'"
          alt="">
      </figure>
      <div class="mb-3">
        <a *ngIf="dialogData?.order?.file_type === 'pdf'"
          href="{{dialogData?.order?.artwork_full_path ? dialogData?.order?.artwork_full_path : ''}}" target="_blank"
          style="color: rgb(54, 11, 245);">Click here to view PDF</a>
      </div>
      <div class="row mb-3" *ngIf="dialogData?.order?.design_status === 3 ">
        <div class="col-6">
          <button (click)="artWork(true)" class="btn btn-success rounded-pill w-100">Accept</button>
        </div>
        <div class="col-6">
          <button (click)="artWorkObj.accepted = false" class="btn btn-primary rounded-pill w-100">Reject</button>
        </div>

        <div class="col-6 mt-3" *ngIf="dialogData?.order?.file_type === 'image'">
          <a href="{{dialogData?.order?.artwork_full_path}}"><button
              class="btn btn-secondary rounded-pill w-100">Download</button></a>
        </div>
      </div>
      <ng-container *ngIf="artWorkObj.accepted === false">
        <textarea class="form-control mb-2 w-100" [(ngModel)]="artWorkObj.reason"></textarea>
        <button [disabled]="!artWorkObj.reason" class="text-white btn btn-red w-100 rounded-pill"
          (click)="artWork(false)">Submit</button>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="dialogData.type === 'quotePayment' && !quoteAccepted">
  <!-- <div class="row mb-3"> -->
  <!-- <div class="col-12">
      <h1 class="mb-lg-3 mb-2">Amount to be paid: <span class="text-red">${{dialogData.quoteData.price}}</span></h1>
    </div>
    <div class="col-6">
      <button (click)="quoteAccepted = true" class="btn btn-success rounded-pill w-100">Accept Quote</button>
    </div>
    <div class="col-6">
      <button (click)="declineQuote()" class="btn btn-primary rounded-pill w-100">Reject Quote</button>
    </div> -->
  <div class="p-0 text-center">
    <h3 class="text-center">Reprint Quote</h3>
    <div class="quantityAmount">
      <p>Quantity: {{dialogData.quoteData.quantity}}</p>
      <p>Amount: ${{dialogData.quoteData.price}}</p>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <button (click)="quoteAccepted = true" class="btn btn-success rounded-pill w-100">Accept</button>
      </div>
      <div class="col-6">
        <button (click)="declineQuote()" class="btn btn-primary rounded-pill w-100">Reject</button>
      </div>
    </div>
  </div>
  <!-- </div> -->
</ng-container>

<ng-container
  *ngIf="(dialogData.typeprint === 'quotePayment' && quoteAccepted) || (dialogData.type === 'designChangeQuote' && designChangesQuoteStatus)">
  <div class="authenticated-right-wrap" style="min-height: 100%;">
    <button type="button" [mat-dialog-close] class="closebtn">
      <mat-icon>cancel</mat-icon>
    </button>
    <div class="edit-profile-form">
      <div class="info-wrapper" [formGroup]="paymentFormObj.paymentForm">
        <h1 class="mb-lg-3 mb-2">Amount to be paid: <span class="text-red">${{dialogData.quoteData.price}}</span></h1>
        <div class="row">
          <!-- <div  class="col-lg-12 mb-3">
            <img src="assets/images/card.png" alt="" width="150" class="ml-auto d-block">
        </div> -->
          <div class="col-lg-12 mb-3">
            <p class="label">Card Number:</p>
            <input type="text" [mask]="cardMask" placeholder="Card Number" class="ng-untouched ng-pristine ng-valid"
              formControlName="card_no" />
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.card_no.touched && paymentFormObj.paymentForm.controls.card_no.hasError('required')">
              Card number is required
            </mat-error>print
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.card_no.touched && paymentFormObj.paymentForm.controls.card_no.hasError('pattern')">
              Card number not valid
            </mat-error>
          </div>
          <div class="col-lg-4 mb-3">
            <p class="label">Expiry Month:</p>
            <select placeholder="Expiry Month" class="ng-untouched ng-pristine ng-valid" formControlName="exp_month"
              (change)="checkValidity('month')">
              <option value=''>Select month</option>
              <option [value]="month" *ngFor="let month of paymentFormObj.monthArray">{{month}}</option>
            </select>
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.exp_month.touched && paymentFormObj.paymentForm.controls.exp_month.hasError('required')">
              Exp month is required
            </mat-error>
          </div>
          <div class="col-lg-4 mb-3">
            <p class="label">Expiry Year:</p>
            <select placeholder="Expiry Year" class="ng-untouched ng-pristine ng-valid" formControlName="exp_year"
              (change)="checkValidity('year')">
              <option value=''>Select year</option>
              <option [value]="year" *ngFor="let year of paymentFormObj.yearArray">{{year}}</option>
            </select>
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.exp_year.touched && paymentFormObj.paymentForm.controls.exp_year.hasError('required')">
              Exp year is required
            </mat-error>
          </div>
          <div class="col-lg-4 mb-3">
            <p class="label">CVC:</p>
            <input type="password" placeholder="cvc" formControlName="cvc" />
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.cvc.touched && paymentFormObj.paymentForm.controls.cvc.hasError('required')">
              CVC number is required
            </mat-error>
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.cvc.touched && paymentFormObj.paymentForm.controls.cvc.hasError('pattern')">
              CVC number not valid
            </mat-error>
          </div>
          <div class="col-lg-12 mb-3">
            <p class="label">Amount($):</p>
            <input type="text" placeholder="Amount" class="ng-untouched ng-pristine ng-valid"
              formControlName="amount" />
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.amount.touched && paymentFormObj.paymentForm.controls.amount.hasError('required')">
              Order amount is required
            </mat-error>
          </div>
          <!-- <div class="col-lg-12 mb-3" *ngIf="dialogData.type === 'designChangeQuote'">
            <p class="label">Description:</p>
            <textarea type="text" placeholder="Description" class="ng-untouched ng-pristine ng-valid"
              formControlName="description"></textarea>
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.description.touched && paymentFormObj.paymentForm.controls.description.hasError('required')">
              Payment description is required
            </mat-error>
          </div> -->


          <div class="row buttons">
            <div class="col-sm-6">
              <button type="submit" class="btn btn-red btn-c" (click)="doPayment()"
                [disabled]="paymentFormObj.progressing">{{
                paymentFormObj.progressing ? 'Progressing..' : 'Pay' }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>



<ng-container *ngIf="isPayment">
  <div class="authenticated-right-wrap" style="min-height: 100%;">
    <button type="button" [mat-dialog-close] class="closebtn">
      <mat-icon>cancel</mat-icon>
    </button>
    <div class="edit-profile-form">
      <div class="info-wrapper" [formGroup]="paymentFormObj.paymentForm">
        <h1 class="mb-lg-3 mb-2">Amount to be paid: </h1>
        <h4>Product Cost :<span class="text-red"> ${{productPrice}}</span></h4>
        <h4>Shipping Charge :<span class="text-red"> ${{shippingCharge ? shippingCharge : 0}}</span></h4>
        <h4>Tax Charge :<span class="text-red"> ${{taxAmount ? taxAmount : 0}}</span></h4>
        <h4>Total Amount :<span class="text-red"> ${{totalPay(productPrice,shippingCharge,taxAmount)}}</span></h4>





        <div class="row">
          <!-- <div  class="col-lg-12 mb-3">
            <img src="assets/images/card.png" alt="" width="150" class="ml-auto d-block">
        </div> -->
          <div class="col-lg-12 mb-3">
            <p class="label">Card Number:</p>
            <input type="text" [mask]="cardMask" placeholder="Card Number" class="ng-untouched ng-pristine ng-valid"
              formControlName="card_no" />
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.card_no.touched && paymentFormObj.paymentForm.controls.card_no.hasError('required')">
              Card number is required
            </mat-error>
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.card_no.touched && paymentFormObj.paymentForm.controls.card_no.hasError('pattern')">
              Card number not valid
            </mat-error>
          </div>
          <div class="col-lg-4 mb-3">
            <p class="label">Expiry Month:</p>
            <select placeholder="Expiry Month" class="ng-untouched ng-pristine ng-valid" formControlName="exp_month"
              (change)="checkValidity('month')">
              <option value=''>Select month</option>
              <option [value]="month" *ngFor="let month of paymentFormObj.monthArray">{{month}}</option>
            </select>
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.exp_month.touched && paymentFormObj.paymentForm.controls.exp_month.hasError('required')">
              Exp month is required
            </mat-error>
          </div>
          <div class="col-lg-4 mb-3">
            <p class="label">Expiry Year:</p>
            <select placeholder="Expiry Year" class="ng-untouched ng-pristine ng-valid" formControlName="exp_year"
              (change)="checkValidity('year')">
              <option value=''>Select year</option>
              <option [value]="year" *ngFor="let year of paymentFormObj.yearArray">{{year}}</option>
            </select>
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.exp_year.touched && paymentFormObj.paymentForm.controls.exp_year.hasError('required')">
              Exp year is required
            </mat-error>
          </div>
          <div class="col-lg-4 mb-3">
            <p class="label">CVC:</p>
            <input type="password" placeholder="cvc" formControlName="cvc" />
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.cvc.touched && paymentFormObj.paymentForm.controls.cvc.hasError('required')">
              CVC number is required
            </mat-error>
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.cvc.touched && paymentFormObj.paymentForm.controls.cvc.hasError('pattern')">
              CVC number not valid
            </mat-error>
          </div>
          <!-- <div class="col-lg-12 mb-3">
            <p class="label">Amount($):</p>
            <input type="text" placeholder="Amount" class="ng-untouched ng-pristine ng-valid"
              formControlName="amount" />
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.amount.touched && paymentFormObj.paymentForm.controls.amount.hasError('required')">
              Order amount is required
            </mat-error>
          </div> -->
          <!-- <div class="col-lg-12 mb-3" *ngIf="dialogData.type === 'designChangeQuote'">
            <p class="label">Description:</p>
            <textarea type="text" placeholder="Description" class="ng-untouched ng-pristine ng-valid"
              formControlName="description"></textarea>
            <mat-error
              *ngIf="paymentFormObj.paymentForm.controls.description.touched && paymentFormObj.paymentForm.controls.description.hasError('required')">
              Payment description is required
            </mat-error>
          </div> -->


          <div class="row buttons">
            <div class="col-sm-6">
              <button type="submit" class="btn btn-red btn-c" (click)="doPayment()">Pay</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="dialogData.type === 'designChangeQuote' && !designChangesQuoteStatus">

  <div class="p-0 text-center">
    <h3 class="text-center">Design Change Quote</h3>
    <div class="quantityAmount">
      <p>Quantity: {{dialogData?.orderData?.make_change_quote?.order_quantity}}</p>
      <p>Amount: $ {{dialogData?.orderData?.make_change_quote?.paid_amount}}</p>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <button (click)="designQuoteAction(true)" class="btn btn-success rounded-pill w-100">Accept</button>
      </div>
      <div class="col-6">
        <button (click)="designQuoteAction(false)" class="btn btn-primary rounded-pill w-100">Reject</button>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="dialogData.type === 'transferOrder'">
  <div class="transferOrder">
    <div class="p-0 text-center">
      <h3 class="text-center">Transfer order to :</h3>
      <div class="form-group">
        <mat-select [(ngModel)]="transferOrderObj.toUserId">
          <mat-option value="">Select User</mat-option>
          <mat-option *ngFor="let user of transferOrderObj.secondaryUserList" [value]="user.id">{{user.full_name}}
          </mat-option>
        </mat-select>
        <div class="text-center pb-4">
          <b> OR</b>
        </div>
        <div>
          <div class="outlineInput">
            <input placeholder="Enter your email" [formControl]="email" (change)="ongiveEmail()">
          </div>
        </div>

      </div>
      <div class="row mb-3">
        <div class="col-12 text-center">
          <button (click)="doTransferOrder()" class="btn btn-success rounded-pill">Transfer</button>
          <!-- [disabled]="!transferOrderObj.toUserId || email.invalid" -->
        </div>
        <!-- <div class="col-6">
        <button (click)="designQuoteAction(false)" class="btn btn-primary rounded-pill w-100">Reject</button>
      </div> -->
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="dialogData.type === 'uploadCustomDesign'">
  <div class="uploadCustomDesign">
    <button type="button" [mat-dialog-close] class="closebtn">
      <mat-icon class="close-red">close</mat-icon>
    </button>
    <h1>Add your design</h1>
    <div class="uploadsec">
      <figure>
        <img (click)="galleryImage.click()"
          [src]="designImagestoupload ? designImagestoupload : './assets/images/upload.svg'" alt=""></figure>
      <input type="file" name="imagePicker" #galleryImage (change)="onImageUpload($event)" accept=".png, .jpg, .jpeg">
      <button type="button" class="btn btn-primary btn-c">
        Upload <mat-icon>upload</mat-icon>
      </button>

    </div>
    <input class="new-design-item" type="text" placeholder="Design name" [(ngModel)]="customDesignName">
    <!-- [disabled]="!(designImagestoupload && customDesignName)" -->
    <div class="submitbtn">
      <button class="btn btn-primary btn-c" (click)="uploadDesignFile()">Submit</button></div>
  </div>
</ng-container>

<ng-container *ngIf="dialogData.type === 'backgroundImage'">
  <div class="artWorksec">
    <div class="p-0 text-center">
      <div class="row background-img-row">
        <div class="col-3">
          <mat-icon class="cp" (click)="backgroundCategoryName = ''" *ngIf="backgroundCategoryName">keyboard_backspace
          </mat-icon>
        </div>


        <div class="col-6">
          <h3 class="text-centerç">Background Image</h3>
        </div>

        <!-- <button class="float-right">My Profile</button> -->
        <!-- <div (click)="myImages()" class="category cp float-right" *ngIf="!backgroundCategoryName">My Images</div> -->

        <div class="col-3 float-right my-images-button" *ngIf="!backgroundCategoryName">
          <button (click)="myImages()" class="btn btn-success rounded-pill w-100">My Images</button>
        </div>
      </div>


      <div class="row" *ngIf="!backgroundCategoryName">
        <!-- <div class="form-group">
          <mat-select [(ngModel)]="bgImageListObj.typeId" (selectionChange)="setCategory()">
            <mat-option value="">--choose type--</mat-option>
            <mat-option *ngFor="let type of bgImageTypeList" [value]="type.id">{{type.name}}
            </mat-option>
          </mat-select>
        </div> -->
        <div class="col-md-3 py-3" *ngFor="let type of bgImageTypeList">
          <div [ngClass]="doparseInt(imageObj.category_id) === type.id ? 'category cp catactive' : 'category cp'"
            (click)="setCategory(type)">{{type.name}}</div>
        </div>
        <div class="col-3 new-col-cancel">
          <button (click)="closeBgImagePopup(false)" class="btn btn-primary rounded-pill cancel-pill-new w-100"
            [mat-dialog-close]>Cancel</button>
        </div>
      </div>
      <ng-container *ngIf="backgroundCategoryName && !uploadOwnBGImageObj?.uploadOwnBGImage">
        <div class="actions">
          <div class="catname" *ngIf="backgroundCategoryName !== 'My Images'">
            <!-- <mat-icon class="cp" (click)="backgroundCategoryName = ''">keyboard_backspace</mat-icon> -->
            Category Name: {{backgroundCategoryName}}
          </div>
          <div class="catname" *ngIf="backgroundCategoryName === 'My Images'">
            <!-- <mat-icon class="cp" (click)="backgroundCategoryName = ''">keyboard_backspace</mat-icon> -->
            {{backgroundCategoryName}}
          </div>
          <button *ngIf="backgroundCategoryName === 'My Images'" class="uploadCusBGImage"
            (click)="uploadOwnBGImageObj.uploadOwnBGImage = true">Upload Your
            Image</button>


          <!-- <button class="uploadCusBGImage" (click)="uploadOwnBGImageObj.uploadOwnBGImage = true">Upload Your Image</button> -->
        </div>

        <perfect-scrollbar class="list-scroll" (psYReachEnd)="onScroll($event)"
          *ngIf="bgImageListObj?.imageList?.length > 0">
          <div class="row">
            <div class="col-md-6 col-lg-4 py-3" *ngFor="let image of bgImageListObj?.imageList">
              <figure class="pr-acc-info w-100 position-relative">
                <img class="d-block"
                  [src]="image?.design_image_url ? image.design_image_url : './assets/images/no-image.png'" alt=""
                  (click)="setBgImage(image)" [ngClass]="imageObj?.id === image?.id ? 'it-img active' : 'it-img'">
                <div class="position-absolute" style="top:10px; right: 10px;">
                  <mat-checkbox [checked]="imageObj.id === image?.id" (change)="setBgImage(image)"></mat-checkbox>
                </div>
              </figure>
            </div>
          </div>
        </perfect-scrollbar>
        <div class="row mb-3 justify-content-center">
          <div [hidden]="!imageLoading" class="alert alert-primary text-center" role="alert">Loading....</div>
          <div *ngIf="bgImageListObj?.imageList?.length === 0 && !imageLoading" class="alert alert-warning text-center"
            role="alert">No image found</div>
        </div>

        <div class="row mb-3">
          <div class="col-6">
            <button (click)="closeBgImagePopup(true)" class="btn btn-success rounded-pill w-100">Ok</button>
          </div>
          <div class="col-6">
            <button (click)="closeBgImagePopup(false)" class="btn btn-primary rounded-pill w-100"
              [mat-dialog-close]>Cancel</button>
          </div>
        </div>
        <!-- <ng-container *ngIf="artWorkObj.accepted === false">
          <textarea class="form-control mb-2" [(ngModel)]="artWorkObj.reason"></textarea>
          <button [disabprintled]="!artWorkObj.reason" class="text-white btn btn-red w-100 rounded-pill"
            (click)="artWork(false)">Submit</button>
        </ng-container> -->
      </ng-container>
      <ng-container *ngIf="backgroundCategoryName && uploadOwnBGImageObj?.uploadOwnBGImage">
        <mat-icon class="cp" (click)="uploadOwnBGImageObj.uploadOwnBGImage = false">keyboard_backspace</mat-icon>
        <!-- <div>
          <label>Image Name:</label>
          <input type="text" [(ngModel)]="uploadOwnBGImageObj.imageName" placeholder="Image name">
        </div> -->
        <!-- {{designImagestoupload | json}} -->
        <div>

        </div>

        <figure class="upload-image">
          <img class="pro" [src]="designImagestoupload ?  designImagestoupload : './assets/images/no-image.png'" alt="">
          <div class="upload">
            <!-- <img (click)="galleryImage1.click()" src="./assets/images/upload.svg" alt=""> -->
            <input type="file" name="imagePicker" #galleryImage1 (change)="onImageUpload($event)"
              accept=".png, .jpg, .jpeg" style="display:none">
          </div>
        </figure>
        <div class="row mb-3 upload-btn">
          <div class="col-md-4">
            <div class="upload">
              <button (click)="galleryImage1.click()" class="btn btn-success rounded-pill w-100">Upload Image</button>
              <!-- <img (click)="galleryImage1.click()" src="./assets/images/upload.svg" alt=""> -->
            </div>
          </div>
          <div class="col-md-4">
            <button (click)="uploadBGImageFile()" class="btn btn-success rounded-pill w-100">Upload & Set Background
              Image</button>
          </div>
          <div class="col-md-4">
            <button (click)="uploadOwnBGImageObj.uploadOwnBGImage = false"
              class="btn btn-primary rounded-pill w-100">Back</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
