import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewAccountComponent } from './view-account/view-account.component';
import { MaterialModule } from '@project/material.module';
import { TermsComponent } from './terms/terms.component';
import { TakeInputComponent } from './take-input/take-input.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SampleParagraphComponent } from './sample-paragraph/sample-paragraph.component';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxMaskModule } from 'ngx-mask';
import { MessageComponent } from './message/message.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  // suppressScrollX: true,
  wheelSpeed: 1,
  swipeEasing: true,
  scrollYMarginOffset: 100,
  scrollXMarginOffset: 100
};


@NgModule({
  declarations: [
    ViewAccountComponent,
    TermsComponent,
    TakeInputComponent,
    SampleParagraphComponent,
    MessageComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    ClipboardModule,
    NgxMaskModule.forChild()

  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class ModalModule { }
