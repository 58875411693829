import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HelperModule } from '@project/helper/helper.module';
import { RouterModule } from '@angular/router';
import { ClientSectionComponent } from './client-section/client-section.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { InnerBannerComponent } from './inner-banner/inner-banner.component';
import { MaterialModule } from '@project/material.module';
import { CardDirective } from './card/card.directive';
import { MatDialogModule } from '@angular/material/dialog';


const COMPONENTS = [HeaderComponent, FooterComponent,
  ClientSectionComponent,
  InnerBannerComponent, CardDirective];

@NgModule({
  declarations: [
    COMPONENTS
  ],
  exports: [
    COMPONENTS
  ],
  imports: [
    CommonModule,
    HelperModule,
    RouterModule,
    SlickCarouselModule,
    MaterialModule,
    MatDialogModule
  ]
})
export class SharedModule { }
