<mat-dialog-content class="mat-typography">
    <button class="btn-close" mat-dialog-close>
        <span class="material-icons">
        clear
        </span>
    </button>

    <div class="wrap-container">
        <figure>
            <img src="./assets/images/ac-view.png" alt="">
        </figure>
    </div>
  
</mat-dialog-content>

