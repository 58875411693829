<footer>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-3 col-sm-4">
        <div class="cWrap d-flex flex-wrap justify-content-center mb-5">
          <div class="contactWrap">
            <img src="./assets/images/fic1.svg" alt="">
          </div>
          <a href="tel:519-426-7275">519-426-7275</a>
        </div>
      </div>
      <div class="col-md-3 col-sm-4">
        <div class="cWrap d-flex flex-wrap justify-content-center mb-5" routerLink="/reach-us"
          routerLinkActive="active">
          <div class="contactWrap">
            <img src="./assets/images/fic2.svg" alt="">
          </div>
          <a>How To Reach Us</a>
        </div>
      </div>
      <div class="col-md-3 col-sm-4">
        <div class="cWrap d-flex flex-wrap justify-content-center mb-5" routerLink="/get-a-quote"
          routerLinkActive="active">
          <div class="contactWrap">
            <img src="./assets/images/fic3.svg" alt="">
          </div>
          <a>Get A Printing Quote</a>
        </div>
      </div>
      <div class="col-lg-10">
        <ul class="list-unstyled d-flex justify-content-between quick-links">
          <li><a [routerLink]="['/products']" routerLinkActive="active">PRODUCTS </a></li>
          <li><a routerLink="/how-the-MME-packages-work" routerLinkActive="active">Marketing Made Easy </a></li>
          <li><a routerLink="/about-us" routerLinkActive="active">ABOUT US </a></li>
          <li><a routerLink="/privacy" routerLinkActive="active">Privacy Policy </a></li>
          <li><a routerLink="/terms-condition" routerLinkActive="active">Terms & Conditions </a></li>
          <!-- <li><a routerLink="/">SAMPLES </a></li> -->
          <li><a routerLink="/welcome-to-your-MME-package" routerLinkActive="active">MARKETING TIPS </a></li>
          <li><a routerLink="/events" routerLinkActive="active">EVENTS </a></li>
          <li><a routerLink="/faq" routerLinkActive="active">FAQ </a></li>
          <li><a routerLink="/reach-us" routerLinkActive="active">CONTACT US</a></li>
        </ul>
      </div>
      <div class="col-lg-12">
        <p class="copy">
          &copy; Copyright {{currentYear}} FPP
        </p>
      </div>
    </div>
  </div>
</footer>
