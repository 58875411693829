import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpEventType
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventService } from '../services/event.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private event: EventService) {
    this.event.loading.next(false);
  }

  removeRequest(req: HttpRequest<any>): any {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.event.loading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.event.loading.next(true);
    // tslint:disable-next-line: deprecation
    return new Observable((observer: any) => {
      const subscription = next.handle(req)
        .subscribe(
          (event) => {
            if (event.type === HttpEventType.DownloadProgress) {
              this.event.isprogress.next({
                val: event.total ? Math.round(event.loaded / event.total * 100).toFixed(0) : 0,
                text: 'Downloading', path: req.urlWithParams,
                req: req.url
              });
            }
            if (event.type === HttpEventType.UploadProgress) {
              this.event.isprogress.next({
                val: event.total ? Math.round(event.loaded / event.total * 100).toFixed(0) : 0,
                text: 'Uploading', path: req.urlWithParams,
                req: req.url
              });
            }
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
