import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-client-section',
  templateUrl: './client-section.component.html',
  styleUrls: ['./client-section.component.scss']
})
export class ClientSectionComponent implements OnInit {
  slideConfig1 = {
    slidesToShow: 1,
    arrows: false,
    dots: true,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 8000,
    Speed: 7000,
  };
  testimonialList: any;
  packageList = [];

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.getTestimonialList();
    // this.getPackagesList();
  }

  // tslint:disable-next-line:typedef
  slickInit(e: any) {
    // console.log('slick initialized');
  }

  getTestimonialList(): void {
    this.api.get('testimonials').subscribe((res: any) => {
      if (res.status === true) {
        this.testimonialList = res.data;
      } else {
        // this.api.alert(res.message, 'warning');
      }
    }, err => {
      this.api.alert('Something went wrong', 'error');
    });
  }

  /*getPackagesList(): void {
    this.api.get('packages').subscribe((res: any) => {
      if (res.status === true) {
        this.packageList = res.data;
        console.log(this.packageList);
      } else {
        this.api.alert(res.message, 'warning');
      }
    }, err => {
      this.api.alert('Something went wrong', 'error');
    })
  }*/

}
