import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { ɵangular_packages_router_router_n } from '@angular/router';

@Component({
  selector: 'app-take-input',
  templateUrl: './take-input.component.html',
  styleUrls: ['./take-input.component.scss']
})
export class TakeInputComponent implements OnInit {
  artWorkObj = {
    accepted: true,
    reason: ''
  };
  paymentFormObj = {
    paymentForm: new FormGroup({}),
    monthArray: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    currentYear: new Date().getFullYear(),
    currentMonth: new Date().getMonth() + 1,
    yearArray: [{}],
    payAmount: null,
    progressing: false
  };
  cardMask = `0000 0000 0000||0000 0000 0000 0||0000 0000 0000 00||0000 0000 0000 000||0000 0000 0000 0000||0000 0000 0000 0000 0||0000 0000 0000 0000 00||0000 0000 0000 0000 000`;

  designChangesQuoteStatus = false;
  transferOrderObj: any = {
    secondaryUserList: [],
    toUserId: ''
  };
  priceId = '';
  // secondaryUserList: any = [];
  quoteAccepted = false;
  designImagestoupload: any;
  customDesignImageFile: any;
  customDesignName = '';

  bgImageTypeList: any;
  // bgImageTypeId: any;
  bgImageListObj: any = {
    typeId: '',
    imageList: [],
    selectImageId: '',
    pageNo: 1,
    totalPage: 0,
    totalRecord: 0
  };
  imageObj: any = {};
  imageLoading = false;
  backgroundCategoryName = '';
  uploadOwnBGImageObj: any = {
    uploadOwnBGImage: false,
    // imageName: '',
    file: {},
    submitting: false
  };
  imagestoupload: any;
  userDetails: any;
  userData: string | null;
  email: FormControl;
  productDetails: any;
  attributeArray: any = [];
  productId: any;
  itemId: any;
  productPrice: any;
  totalVal: any;
  quantity: any;
  isPayment = false;
  payForm: FormGroup;
  shippingData: any;
  taxRate: any;
  shippingCharge: any;
  taxAmount: any;
  materialList: any = [];
  checked: any;


  constructor(private api: ApiService, private dialogRef: MatDialogRef<TakeInputComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any, private storage: StorageService) { }

  ngOnInit(): void {

    this.userData = localStorage.getItem('user');
    // console.log('user---->', this.userData);

    // console.log('73', this.dialogData);
    if (this.dialogData.type === 'quotePayment') {
      this.quoteAccepted = false;
      // this.initPaymentForm();
    } else if (this.dialogData.type === 'transferOrder') {
      this.email = new FormControl();
      this.getSecondaryUserList();
    } else if (this.dialogData.type === 'backgroundImage') {
      // this.bgImageListObj.typeId = this.dialogData?.imageObj?.categoryId ? Number(this.dialogData?.imageObj?.categoryId) : '';
      this.bgImageListObj.typeId = this.dialogData?.imageObj?.category_id ? this.dialogData?.imageObj?.category_id : '';
      // this.bgImageListObj.selectImageId = this.dialogData?.imageObj?.id ? this.dialogData?.imageObj?.id : null;
      this.imageObj = this.dialogData?.imageObj ? this.dialogData?.imageObj : {};
      this.imageObj.id = this.dialogData?.imageObj?.imageId ? this.dialogData?.imageObj?.imageId : null;
      console.log('imageObj', this.imageObj);
      this.userDetails = this.storage.getUser();
      this.getBGImageCatList();
      this.getBGImageList();
    } else if (this.dialogData.type === 'printMoreCount') {
      console.log(this.dialogData);

      this.getProductDetails(this.dialogData.id);


    }
  }


  // tslint:disable-next-line:typedef
  ongiveEmail() {
    // console.log(this.email.value);

  }
  submitPrint(): void {
    this.dialogRef.close({ count: this.dialogData.count });
  }

  artWork(status: boolean): void {
    this.artWorkObj.accepted = status;
    if (this.artWorkObj.accepted === false && !this.artWorkObj.reason) {
      this.api.alert('Please enter reason to to reject the artwork', 'warning');
    } else {
      const reqData = {
        order_id: this.dialogData.order?.id.toString(),
        status: this.artWorkObj.accepted,
        message: this.artWorkObj.accepted === false ? this.artWorkObj.reason : ''
      };
      this.api.post('order/accept-decline-design', reqData).subscribe((res: any) => {
        if (res.status === true) {
          this.api.alert(res.message, 'success');
          this.dialogRef.close({ success: true });
        } else if (res.status === 201) {
          this.api.alert(res.message, 'warning');
        } else {
          this.api.alert('Something went wrong', 'error');
        }
      }, err => {
        this.paymentFormObj.progressing = false;
        this.api.alert('Something went wrong', 'error');
      });
    }
  }

  initPaymentForm(): void {
    this.paymentFormObj.yearArray = [];
    for (let i = this.paymentFormObj.currentYear; i < (this.paymentFormObj.currentYear + 10); i++) {
      this.paymentFormObj.yearArray.push(i);
    }
    this.paymentFormObj.paymentForm = new FormGroup({
      card_no: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{8,16}$/)]), // , Validators.maxLength(16)
      cvc: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]), // Validators.maxLength(3),
      // amount: new FormControl({ value: this.dialogData.quoteData.price, disabled: true }, []),
      exp_month: new FormControl('', [Validators.required]),
      exp_year: new FormControl('', [Validators.required])
    });
    // if (this.dialogData.type === 'designChangeQuote') {
    //   this.paymentFormObj.paymentForm.addControl('description', new FormControl('', [Validators.required]));
    // }
  }


  initPayForm(): any {
    this.payForm = new FormGroup({
      card_no: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{8,16}$/)]), // , Validators.maxLength(16)
      cvc: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]{3,4}$/)]), // Validators.maxLength(3),
      exp_month: new FormControl('', [Validators.required]),
      exp_year: new FormControl('', [Validators.required])
    });

  }

  declineQuote(): void {
    this.quoteAccepted = false;
    // this.dialogRef.close({ success: false });
    const reqData = {
      order_print_id: this.dialogData?.quoteData?.id.toString()
    };
    this.api.post('order/decline-print-more', reqData).subscribe((res: any) => {
      if (res.status === true) {
        this.api.alert(res.message, 'success');
        this.dialogRef.close({ success: true });
      } else if (res.status === 201) {
        this.api.alert(res.message, 'warning');
      } else {
        this.api.alert('Something went wrong', 'error');
      }
    }, err => {
      this.paymentFormObj.progressing = false;
      this.api.alert('Something went wrong', 'error');
    });
  }

  doPayment(): void {
    if (this.paymentFormObj.paymentForm.valid) {
      let reqUrl = '';
      this.paymentFormObj.progressing = true;
      const formData = new FormData();
      for (const key in this.paymentFormObj.paymentForm.value) {
        if (key !== 'amount') {
          formData.append(key, this.paymentFormObj.paymentForm.value[key]);
        }
      }
      formData.append('amount', this.totalPay(this.productPrice, this.shippingCharge, this.taxAmount));
      formData.append('order_id', this.dialogData.order.order_id);
      formData.append('product_price_id', this.itemId);
      // reqUrl = 'order/print-payment';

      // formData.append('quantity', this.quantity);
      formData.append('shipping_charge', this.shippingCharge);
      formData.append('tax_amount', this.taxAmount);

      reqUrl = 'order/print-now';


      this.api.postMultiData(reqUrl, formData).subscribe((res: any) => {
        this.paymentFormObj.progressing = false;
        if (res.status === true) {
          this.dialogRef.close({ success: true });
          this.api.alert(res.message, 'success');

          // this.router.navigate(['account']);
          // let encOrderId = this.storageService.encription(res?.data?.id, 'ORDER-ID').toString();
          // this.router.navigate([`/order-details`, encOrderId]);
        } else if (res.status === 201) {
          this.api.alert(res.message, 'warning');
        } else {
          this.api.alert(res?.message ? res?.message : 'Something went wrong', 'error');
        }
      }, err => {
        this.paymentFormObj.progressing = false;
        this.api.alert(err?.message ? err?.message : 'Something went wrong', 'error');
      });
    } else {
      this.paymentFormObj.paymentForm.markAllAsTouched();
    }
  }

  checkValidity(type: string): any {
    // tslint:disable-next-line:max-line-length
    // tslint:disable-next-line:radix
    if (parseInt(this.paymentFormObj.paymentForm.controls.exp_year.value) === this.paymentFormObj.currentYear && parseInt(this.paymentFormObj.paymentForm.controls.exp_month.value) < this.paymentFormObj.currentMonth) {
      this.api.alert('Card validation over', 'warning');
      if (type === 'year') {
        this.paymentFormObj.paymentForm.patchValue({
          exp_year: ''
        });
      } else {
        this.paymentFormObj.paymentForm.patchValue({
          exp_month: ''
        });
      }
    }
  }

  designQuoteAction(accept: boolean): void {
    this.designChangesQuoteStatus = accept;
    if (accept) {
      this.dialogData.quoteData = {
        price: this.dialogData.orderData?.make_change_quote?.paid_amount
      };
      this.initPaymentForm();
    } else {
      const reqData = {
        order_id: this.dialogData?.orderData?.id.toString()
      };
      this.api.post('order/decline-redesign', reqData).subscribe((res: any) => {
        if (res.status === true) {
          this.api.alert(res.message, 'success');
          this.dialogRef.close({ success: true });
        } else if (res.status === 201) {
          this.api.alert(res.message, 'warning');
        } else {
          this.api.alert('Something went wrong', 'error');
        }
      }, err => {
        this.paymentFormObj.progressing = false;
        this.api.alert('Something went wrong', 'error');
      });
    }
  }

  getSecondaryUserList(): void {
    this.api.get('transferable-users').subscribe((res: any) => {
      if (res.status === true) {
        this.transferOrderObj.secondaryUserList = res.data;
        // console.log('transfer2nd', this.transferOrderObj, this.transferOrderObj.secondaryUserList);

      } else if (res.status === 201) {
        this.api.alert(res.message, 'warning');
      } else {
        this.api.alert('Something went wrong', 'error');
      }
    }, err => {
      this.paymentFormObj.progressing = false;
      this.api.alert('Something went wrong', 'error');
    });
  }

  doTransferOrder(): void {
    if (!this.transferOrderObj.toUserId && !this.email.value) {
      this.api.alert('Please choose an user to transfer order', 'warning');
    } else if (this.email.value) {
      // console.log('275', this.dialogData);

      const emailData = {
        order_id: this.dialogData.order.id.toString(),
        email: this.email.value
      };
      this.api.post(`transfer-ordered-product-email`, emailData).subscribe((response: any) => {
        // console.log('277', response);
        if (response.status) {
          this.api.alert(response.message, 'success');
          this.dialogRef.close({ success: true });
        }

      });

    }
    else {
      // console.log(this.dialogData, '292');

      const reqData = {
        order_id: this.dialogData.order?.order_id.toString(),
        user_id: this.transferOrderObj.toUserId.toString()
      };
      this.api.post('transfer-ordered-product', reqData).subscribe((res: any) => {
        if (res.status === true) {
          this.api.alert(res.message, 'success');
          this.dialogRef.close({ success: true });
        } else if (res.status === 201) {
          this.api.alert(res.message, 'warning');
        } else {
          this.api.alert('Something went wrong', 'error');
        }
      }, err => {
        this.paymentFormObj.progressing = false;
        this.api.alert('Something went wrong', 'error');
      });
    }
  }

  onImageUpload(fileData: any): void {
    if (!fileData) {
      return;
    }
    const file: File = fileData.target.files[0];
    this.customDesignImageFile = file;
    const ext = file?.name.split('.').pop();
    if (file.type.match(/image\/*/) === null || !(ext === 'jpg' || ext === 'jpeg' || ext === 'png')) {
      // console.log('Only jpg, jpeg, png file formats are supported.');
      this.api.alert('Only jpg, jpeg, png file formats are supported.', 'error');
    } else if (file.size > 6000000) {
      this.api.alert('You can upload image upto 6 MB.', 'error');
    } else {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.designImagestoupload = e.target.result;
      };
      reader.readAsDataURL(fileData.target.files[0]);
    }
  }

  uploadDesignFile(): void {
    if (this.designImagestoupload && this.customDesignName) {
      this.dialogRef.close({ file: this.customDesignImageFile, fileName: this.customDesignName });
    } else if (!this.customDesignImageFile && !this.customDesignName) {
      this.api.alert('Please upload design file', 'warning');
    } else {
      this.api.alert(this.customDesignImageFile ? 'Please choose a design file' : this.customDesignName ? 'Please put design name' : 'Something Wrong', 'error');

    }
  }

  getBGImageCatList(): void {
    this.api.get('background-type/list').subscribe((res: any) => {
      if (res.status === true) {
        // this.api.alert(res.message, 'success');
        this.bgImageTypeList = res.data;
      } else if (res.status === 201) {
        this.api.alert(res.message, 'warning');
      } else {
        this.api.alert('Something went wrong', 'error');
      }
    }, err => {
      this.paymentFormObj.progressing = false;
      this.api.alert('Something went wrong', 'error');
    });
  }

  getBGImageList(): void {
    if (this.bgImageListObj.pageNo === 1) {
      this.bgImageListObj.imageList = [];
    }
    // alert('true');
    this.imageLoading = true;
    this.api.post(`background-image?page=` + this.bgImageListObj?.pageNo, { id: this.bgImageListObj?.typeId ? this.bgImageListObj?.typeId : '' }).subscribe((res: any) => {


      if (res.status === true) {
        // this.api.alert(res.message, 'success');
        // this.bgImageListObj = res.data;
        this.bgImageListObj.totalPage = res?.data?.last_page;
        this.bgImageListObj.totalRecord = res?.data?.total;
        res.data.data.map((imagedata: any) => {
          // console.log(imagedata);
          // for (let i = 0; i < 12; i++) {
          this.bgImageListObj.imageList.push(imagedata);
          // }
        });
        this.imageLoading = false;
      } else if (res.status === 201) {
        this.imageLoading = false;
        this.api.alert(res.message, 'warning');
      } else {
        this.imageLoading = false;
        this.api.alert('Something went wrong', 'error');
      }
    }, err => {
      this.imageLoading = false;
      // this.paymentFormObj.progressing = false;
      this.api.alert('Something went wrong', 'error');
    });
  }

  setBgImage(image: any): void {
    if (this.imageObj?.id === image?.id) {
      this.imageObj = {};
    } else {
      this.imageObj = image;
    }
  }

  closeBgImagePopup(type: boolean): void {
    if (type) {
      this.imageObj.changeImage = true;
    } else {
      this.imageObj = { changeImage: false };
    }
    if (this.imageObj) {
      this.dialogRef.close(this.imageObj);
    } else {
      this.api.alert('Please choose background image', 'error');
    }
  }

  onScroll(event: any): void {
    if (this.imageLoading) { return; }
    // console.log(event);
    if (this.bgImageListObj.totalPage > this.bgImageListObj.pageNo) {
      this.bgImageListObj.pageNo += 1;
      this.getBGImageList();
    }
  }

  setCategory(catObj: any): void {
    this.bgImageListObj.typeId = catObj.id;
    this.backgroundCategoryName = catObj.name;
    this.bgImageListObj.pageNo = 1;
    this.getBGImageList();
  }

  myImages(): void {
    // this.bgImageListObj.typeId = catObj.id;
    this.backgroundCategoryName = 'My Images';
    this.bgImageListObj.pageNo = 1;
    this.getUserBGImageList();
  }

  getUserBGImageList(): void {
    if (this.bgImageListObj.pageNo === 1) {
      this.bgImageListObj.imageList = [];
    }
    // alert('true');
    console.log(this.userDetails);

    this.imageLoading = true;
    this.api.post('background-user/list', { id: this.userDetails.id }).subscribe((res: any) => {


      if (res.status === true) {
        // this.api.alert(res.message, 'success');
        // this.bgImageListObj = res.data;
        this.bgImageListObj.totalPage = res?.data?.last_page;
        this.bgImageListObj.totalRecord = res?.data?.total;
        res.data.data.map((imagedata: any) => {
          // console.log(imagedata);
          // for (let i = 0; i < 12; i++) {
          this.bgImageListObj.imageList.push(imagedata);
          // }
        });
        this.imageLoading = false;
      } else if (res.status === 201) {
        this.imageLoading = false;
        this.api.alert(res.message, 'warning');
      } else {
        this.imageLoading = false;
        this.api.alert('Something went wrong', 'error');
      }
    }, err => {
      this.imageLoading = false;
      // this.paymentFormObj.progressing = false;
      this.api.alert('Something went wrong', 'error');
    });
  }

  // tslint:disable-next-line:ban-types
  doparseInt(input: string): Number {
    // tslint:disable-next-line:radix
    return parseInt(input);
  }

  uploadBGImageFile(): void {
    console.log('514', this.uploadOwnBGImageObj);

    // if (this.uploadOwnBGImageObj.submitting) {
    //   return;
    // }
    // if (!this.uploadOwnBGImageObj?.imageName) {
    //   this.api.alert('Please choose a background image file name', 'error');
    //   return;
    // } else
    if (!this.customDesignImageFile) {
      this.api.alert('Please choose a background image file', 'error');
      return;
    }
    // && this.uploadOwnBGImageObj?.imageName
    console.log('526', this.customDesignImageFile, this.designImagestoupload);

    if (this.customDesignImageFile && this.designImagestoupload) {
      this.uploadOwnBGImageObj.submitting = true;
      // this.dialogRef.close({ file: this.customDesignImageFile, fileName: this.customDesignName });
      const reqData = new FormData();
      reqData.append('images', this.customDesignImageFile);
      // reqData.append('image_name', this.uploadOwnBGImageObj?.imageName);
      // reqData.append('category_id', this.bgImageListObj.typeId);
      reqData.append('user_id', this.userDetails.id);
      this.api.postMultiData('background-image/add', reqData).subscribe((res: any) => {
        this.uploadOwnBGImageObj.submitting = false;
        if (res.status === true) {
          this.api.alert(res.message, 'success');
          // this.bgImageListObj.imageList = [];
          // this.bgImageListObj.imageList[0] = res.data;
          // this.imageObj.id = this.bgImageListObj.imageList[0]?.id;
          this.setBgImage(res.data);
          this.closeBgImagePopup(true);
          this.uploadOwnBGImageObj.uploadOwnBGImage = false;
        } else {
          this.api.alert(res.message, 'warning');
        }
      }, err => {
        this.uploadOwnBGImageObj.submitting = false;
        this.api.alert('Something went wrong', 'warning');
      });
    } else {
      this.api.alert('Please choose a background image file', 'error');
    }
  }

  // tslint:disable-next-line:typedef
  goToImage() {
    this.api.get(`background-user/list/${this.userData}`).subscribe((res: any) => {
      // console.log('440', res);

    });

  }


  getProductDetails(id: any): void {
    this.api.get(`product/${id}`).subscribe((res: any) => {
      if (res.status) {
        this.productDetails = res?.data;
        console.log('prdet', this.productDetails);

        this.attributeArray = [];
        if (this.productDetails?.product_attribute) {
          Object.keys(this.productDetails?.product_attribute).map((key: any, index: number) => {
            this.attributeArray[index] = {
              attTitle: key.replace('_', ' ').toUpperCase(),
              attValue: this.productDetails?.product_attribute[key]
            };
          });
        }
        console.log(this.attributeArray, 'AttributeArray');
      } else {
        // this.error = res;
      }
    }, (err: Error) => {
      // this.error = err;
      this.api.alert('Something went wrong', 'warning');
    });
  }


  onChangeProduct(product: any, ev: any): any {
    console.log(product, 'product-->>');
    // this.checked = ev.target.checked;
    this.productId = product.product_id;
    this.itemId = product.id;
    this.productPrice = product.price;
    // if (this.quantity) {
    //   this.totalVal = this.quantity * this.productPrice;
    // }
    if (ev.target.checked) {
      this.materialList.push(product);
    } else {
      const index = this.materialList.indexOf(product);
      this.materialList.splice(index, 1);
      this.productPrice = '';
      this.priceId = '';
    }
  }


  qty(ev: any): any {
    console.log(ev.target.value);
    this.quantity = ev.target.value;
    this.totalVal = (this.productPrice * this.quantity).toFixed(2);
    console.log(this.totalVal);

  }

  print(): any {
    this.isPayment = true;
    this.initPaymentForm();
    this.getShipping();
    this.getTax();
  }

  getShipping(): any {
    this.api.get(`shipping-charges`).subscribe((resp: any) => {
      this.shippingData = resp.data;
      const shiprate = parseFloat(this.shippingData?.shipping_percent);
      console.log('shiprate', shiprate);
      if (shiprate !== undefined && shiprate > 0) {

        this.shippingCharge = parseFloat(((this.productPrice * shiprate) / 100).toFixed(2));


      }
      // if (this.shippingCharge < parseFloat(this.shippingData?.min_shipping_charge)) {
      //   this.shippingCharge = parseFloat(this.shippingData?.min_shipping_charge);
      // }
    });
  }

  getTax(): any {
    if (this.productDetails.taxable === 1) {
      this.api.get('tax-charges').subscribe((taxres: any) => {
        this.taxRate = parseFloat(taxres.data?.tax_percent);
        console.log('tax', this.taxRate);
        if (this.taxRate !== undefined && this.taxRate > 0) {
          // const printablePrice = this.istaxble.reduce((a: any, b: any) => Number(a) + Number(b.price), 0);
          this.taxAmount = parseFloat(((this.productPrice * this.taxRate) / 100).toFixed(2));
        }
      });
    } else {
      this.taxAmount = 0;
    }

  }

  totalPay(a: any, b: any, c: any): any {
    console.log(a, b, c);

    let total = 0;
    total = Number(a) + Number(b) + Number(c);
    return total;
  }
}
