import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '@project/services/api.service';
import { ClipboardService } from 'ngx-clipboard';
// import { ClipboardModule, ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-sample-paragraph',
  templateUrl: './sample-paragraph.component.html',
  styleUrls: ['./sample-paragraph.component.scss']
})
export class SampleParagraphComponent implements OnInit {
  id: any;
  paraData: any = [];
  isCopy = false;
  index: any;
  product_name: any;

  constructor(private dialogRef: MatDialogRef<SampleParagraphComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any, private api: ApiService,
    // tslint:disable-next-line:variable-name
              private _clipboardService: ClipboardService) { }

  ngOnInit(): void {

    // console.log('dialog', this.dialogData);
    this.id = this.dialogData.orderID;
    if (this.dialogData.orderID) {
      this.getSample();
    }


  }
  // tslint:disable-next-line:typedef
  copyText(text: any, idx: any) {
    // console.log('34', text, idx);
    this.index = idx;
    this.isCopy = false;
    this._clipboardService.copyFromContent(text.replace(/<[^>]*>/g, ''));
    // for (let i = 0; i < this.paraData.lenght; i++) {
    //   if (i === idx) {
    this.isCopy = true;
    //   }
    // }
    this.dialogRef.close();
  }

  // tslint:disable-next-line:typedef

  // tslint:disable-next-line:typedef
  getSample() {
    this.api.get(`brochure-sample-paragraphs/${this.id}`).subscribe((res: any) => {
      // console.log('para==>', res);
      if (res) {
        this.paraData = res.data.data;
        this.product_name = res.data.product_name[0];
        // console.log(this.paraData);

      }

    });
  }

}
