<header>
  <div class="topHead">
    <div class="container">
      <div class="row xs-item__centered">
        <div class="col-auto mr-auto mb-lg-0 mb-3 xs-W__100">
          <!-- <h4 class="mb-0 text-primary">Marketing Made Easy</h4> -->
          <h4 class="mb-0 text-primary eventname">{{eventData?.name}}</h4>
        </div>
        <div class="col-auto ml-auto yellow_header">
          <div class="row xs-item__centered">
            <!-- <div class="col-auto mb-lg-0 mb-3 eventNameTxt">
              <h4>{{eventData?.name}}</h4>
            </div> -->
            <div class="col-auto mb-lg-0 mb-3 datesec">
              <h6 class="mb-0 text-deep"><svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="white" />
                  <path
                    d="M26.6667 17V19.8M21.3333 17V19.8M18 22.6H30M19.3333 18.4H28.6667C29.403 18.4 30 19.0268 30 19.8V29.6C30 30.3732 29.403 31 28.6667 31H19.3333C18.597 31 18 30.3732 18 29.6V19.8C18 19.0268 18.597 18.4 19.3333 18.4Z"
                    stroke="#CA0D17" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <!-- Friday, April 16</h4> -->
                {{ eventData?.date | date : 'EEEE, MMM d'}}
              </h6>
            </div>
            <div class="col-auto mb-lg-0 mb-3 timesec">
              <h6 class="mb-0 text-deep">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="24" cy="24" r="24" fill="white" />
                  <path
                    d="M24 19.8V24L26.8 25.4M31 24C31 27.866 27.866 31 24 31C20.134 31 17 27.866 17 24C17 20.134 20.134 17 24 17C27.866 17 31 20.134 31 24Z"
                    stroke="#E30613" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                <!-- 3:00 pm EST -->
                {{ eventData?.time }} {{eventData?.time_zone}}
              </h6>
            </div>

            <div class="col-auto mb-lg-0 mb-3 d-flex align-items-center btnsec">
              <button class="btn btn-c bg-light" (click)="regAUser()">
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.5455 13V11.6667C10.5455 10.9594 10.2773 10.2811 9.79991 9.78105C9.32254 9.28095 8.6751 9 8 9H3.54545C2.87036 9 2.22291 9.28095 1.74555 9.78105C1.26818 10.2811 1 10.9594 1 11.6667V13M13.0909 4.33333V8.33333M15 6.33333H11.1818M8.31818 3.66667C8.31818 5.13943 7.17854 6.33333 5.77273 6.33333C4.36691 6.33333 3.22727 5.13943 3.22727 3.66667C3.22727 2.19391 4.36691 1 5.77273 1C7.17854 1 8.31818 2.19391 8.31818 3.66667Z"
                    stroke="#1D1D1B" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                Sign Up Now</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bottomHead pt-3" id="myHeader">
    <div class="container">
      <div class="row">
        <div class="col-auto mr-auto logo">
          <a [routerLink]="['/home']" routerLinkActive="router-link-active"><img src="assets/images/logo.svg"
              alt=""></a>
        </div>
        <div class="col">
          <!-- <div class="btnRow">
            <div class="d-flex ">
              <button class="btn btn-light btn-c ml-auto">
                <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 13V11.6667C11 10.9594 10.7366 10.2811 10.2678 9.78105C9.79893 9.28095 9.16304 9 8.5 9H3.5C2.83696 9 2.20107 9.28095 1.73223 9.78105C1.26339 10.2811 1 10.9594 1 11.6667V13M8.5 3.66667C8.5 5.13943 7.38071 6.33333 6 6.33333C4.61929 6.33333 3.5 5.13943 3.5 3.66667C3.5 2.19391 4.61929 1 6 1C7.38071 1 8.5 2.19391 8.5 3.66667Z"
                    stroke="#1D1D1B" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Login
              </button>
              <button class="btn btn-light btn-c ml-3">
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.5455 13V11.6667C10.5455 10.9594 10.2773 10.2811 9.79991 9.78105C9.32254 9.28095 8.6751 9 8 9H3.54545C2.87036 9 2.22291 9.28095 1.74555 9.78105C1.26818 10.2811 1 10.9594 1 11.6667V13M13.0909 4.33333V8.33333M15 6.33333H11.1818M8.31818 3.66667C8.31818 5.13943 7.17854 6.33333 5.77273 6.33333C4.36691 6.33333 3.22727 5.13943 3.22727 3.66667C3.22727 2.19391 4.36691 1 5.77273 1C7.17854 1 8.31818 2.19391 8.31818 3.66667Z"
                    stroke="#1D1D1B" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

                Sign Up
              </button>
            </div>
          </div> -->

          <div class="row mx-0 justify-content-end align-items-center menuwrapper">
            <div class="menuBackDrop d-xl-none" (click)="closeMenu()">

            </div>
            <div class="mainMenuWrap w-100" (click)="closeMenu()">
              <div class="d-flex d-xl-none align-items-center border-bottom py-2 mb-3 ">
                <div class="col-auto">
                  <a routerLink='/' class="weblogo">
                    <img draggable="false" src="assets/images/logo.svg" width="130" alt="">
                  </a>
                </div>
                <div class=" ml-auto mr-3">
                  <button class="btn shadow-none text-dark p-0"><i
                      class="material-icons align-middle">close</i></button>
                </div>
              </div>
              <nav>
                <ul class="mb-0 d-flex justify-content-end align-items-center list-unstyled">
                  <li><a routerLinkActive="active" routerLink="/products">PRODUCTS</a></li>
                  <li><a routerLinkActive="active" routerLink="/how-it-works">How It Works</a></li>
                  <li><a routerLinkActive="active" routerLink="/how-the-MME-packages-work">Marketing Made Easy</a></li>
                  <li><a routerLinkActive="active" routerLink="/about-us">ABOUT US</a></li>
                  <li><a routerLinkActive="active" routerLink="/samples">SAMPLES</a></li>
                  <li><a routerLinkActive="active" routerLink="/welcome-to-your-MME-package">MARKETING TIPS</a></li>
                  <li><a routerLinkActive="active" routerLink="/events">EVENTS</a></li>
                  <li><a routerLinkActive="active" routerLink="/faq">FAQ</a></li>
                  <li><a routerLinkActive="active" routerLink="/reach-us">CONTACT US</a></li>
                  <li class="shopping-cart-sec">
                    <a (click)="goToCart()" class="deskcart">
                      <img src="./assets/images/shopping-cart-icon-1.svg" alt="">
                      <h6 *ngIf="userData != undefined">{{count}}</h6>
                    </a>
                  </li>
                  <li class="mobcart">
                      <span (click)="goToCart()">Cart <label class="cartCount" *ngIf="userData != undefined">{{count}}</label></span>
                  </li>
                  <li class="d-flex loginRegbtb">
                    <button class="btn btn-light btn-c" [routerLink]="['/login']" *ngIf="!userData">
                      <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M11 13V11.6667C11 10.9594 10.7366 10.2811 10.2678 9.78105C9.79893 9.28095 9.16304 9 8.5 9H3.5C2.83696 9 2.20107 9.28095 1.73223 9.78105C1.26339 10.2811 1 10.9594 1 11.6667V13M8.5 3.66667C8.5 5.13943 7.38071 6.33333 6 6.33333C4.61929 6.33333 3.5 5.13943 3.5 3.66667C3.5 2.19391 4.61929 1 6 1C7.38071 1 8.5 2.19391 8.5 3.66667Z"
                          stroke="#1D1D1B" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      Login
                    </button>
                    <button class="btn btn-light btn-c" [routerLink]="['/register']" *ngIf="!userData">
                      <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M10.5455 13V11.6667C10.5455 10.9594 10.2773 10.2811 9.79991 9.78105C9.32254 9.28095 8.6751 9 8 9H3.54545C2.87036 9 2.22291 9.28095 1.74555 9.78105C1.26818 10.2811 1 10.9594 1 11.6667V13M13.0909 4.33333V8.33333M15 6.33333H11.1818M8.31818 3.66667C8.31818 5.13943 7.17854 6.33333 5.77273 6.33333C4.36691 6.33333 3.22727 5.13943 3.22727 3.66667C3.22727 2.19391 4.36691 1 5.77273 1C7.17854 1 8.31818 2.19391 8.31818 3.66667Z"
                          stroke="#1D1D1B" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>

                      Sign Up
                    </button>
                    <!-- <button class="btn btn-light btn-c ml-lg-3 ml-0 mb-1" [routerLink]="['/login']" *ngIf="loggedInUser" (click)="logout()">
                        <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M11 13V11.6667C11 10.9594 10.7366 10.2811 10.2678 9.78105C9.79893 9.28095 9.16304 9 8.5 9H3.5C2.83696 9 2.20107 9.28095 1.73223 9.78105C1.26339 10.2811 1 10.9594 1 11.6667V13M8.5 3.66667C8.5 5.13943 7.38071 6.33333 6 6.33333C4.61929 6.33333 3.5 5.13943 3.5 3.66667C3.5 2.19391 4.61929 1 6 1C7.38071 1 8.5 2.19391 8.5 3.66667Z"
                            stroke="#1D1D1B" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Logout
                      </button> -->
                  </li>
                  <li>
                    <div class="cs-position profilesec deskview">
                      <button (click)="openMenu()" class="btn shadow-none text-white d-xl-none d-inline-flex"><i
                          class="material-icons align-middle">notes</i></button>
                      <div class="d-flex align-items-center ml-3" *ngIf="userData">
                        <div class="userImg" *ngIf="userData?.profile_photo_url">
                          <img
                            [src]="userData?.profile_photo_url ? userData?.profile_photo_url : './assets/images/no-image-user.jpeg'"
                            alt="User Profile Image">
                        </div>
                        <div class="userImg" *ngIf="!userData?.profile_photo_url">
                          <div class="abbr">{{acronym}}</div>
                        </div>
                        <span class="material-icons text-white cp" [matMenuTriggerFor]="menu">
                          expand_more
                        </span>
                        <mat-menu #menu="matMenu">
                          <!-- <p class="title">Hi, {{userData?.full_name?.trim() ? userData?.full_name?.trim() : 'user'}}</p> -->
                          <!-- <button mat-menu-item [routerLink]="['/account']">
                            {{userData?.first_name?.trim() ? userData?.first_name?.trim() + "'s" : 'user' | titlecase}}
                            Profile</button> -->
                          <!-- <button mat-menu-item [routerLink]="['/orders']">Orders</button> -->
                          <button mat-menu-item [routerLink]="['/account']">

                            Products</button>
                          <button mat-menu-item [routerLink]="['/edit-account']">Edit Profile</button>
                          <button mat-menu-item [routerLink]="['/change-password']">Change Password</button>

                          <!-- <button mat-menu-item [routerLink]="['/add-to-cart']">Cart</button> -->
                          <button mat-menu-item (click)="logout()">Logout</button>
                        </mat-menu>
                      </div>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="cs-position profilesec mobview">
              <div class="d-flex align-items-center ml-3" *ngIf="userData">
                <div class="userImg" *ngIf="userData?.profile_photo_url">
                  <img
                    [src]="userData?.profile_photo_url ? userData?.profile_photo_url : './assets/images/no-image-user.jpeg'"
                    alt="User Profile Image">
                </div>
                <div class="userImg" *ngIf="!userData?.profile_photo_url">
                  <div class="abbr">{{acronym}}</div>
                </div>
                <span class="material-icons text-white cp" [matMenuTriggerFor]="menu">
                  expand_more
                </span>
                <mat-menu #menu="matMenu">
                  <!-- <p class="title">Hi, {{userData?.full_name?.trim() ? userData?.full_name?.trim() : 'user'}}</p> -->
                  <!-- <button mat-menu-item [routerLink]="['/account']">
                    {{userData?.first_name?.trim() ? userData?.first_name?.trim() + "'s" : 'user' | titlecase}}
                    Profile</button> -->
                  <!-- <button mat-menu-item [routerLink]="['/orders']">Orders</button> -->
                  <button mat-menu-item [routerLink]="['/account']">

                    Products</button>
                  <button mat-menu-item [routerLink]="['/edit-account']">Edit Profile</button>
                  <button mat-menu-item [routerLink]="['/change-password']">Change Password</button>

                  <!-- <button mat-menu-item [routerLink]="['/add-to-cart']">Cart</button> -->
                  <button mat-menu-item (click)="logout()">Logout</button>
                </mat-menu>
              </div>
              <button (click)="openMenu()" class="btn shadow-none text-white d-xl-none d-inline-flex"><i
                  class="material-icons align-middle">notes</i></button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="signUpNow">
    <div class="signUpNowWrap">
      <img src="./assets/images/clear.svg" alt="" id="clr" (click)="clrNowSignUp()">
      <img src="./assets/images/sgNow.png" alt="" class="img-c">
      <div class="fs-62 text-white text-center mb-5">
        Sign up for the Marketing Made Easy Tipline
      </div>
      <div class="inputWrap">
        <p class="text-white text-center">Full Name</p>
        <input type="text" placeholder="Joanthan Doe">
      </div>
      <div class="inputWrap">
        <p class="text-white text-center">Email</p>
        <input type="email" placeholder="jonathan@gmail.com">
      </div>
      <button class="btn btn-c btn-primary">Subscribe</button>
    </div>
  </div>
</header>
