import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private storage: StorageService
  ) { }

  loading = new BehaviorSubject(false);
  isLoading = this.loading.asObservable();

  login = new BehaviorSubject(this.storage.isAuthenticate());
  isLogin = this.login.asObservable();

  user = new BehaviorSubject(false);
  userDetails = this.user.asObservable();

  isTemp = new BehaviorSubject(false);
  isTempData = this.isTemp.asObservable();

  isprogress = new BehaviorSubject({});
  isProgress = this.isprogress.asObservable();

  count = new BehaviorSubject(false);
  isCount = this.count.asObservable();


  // tslint:disable-next-line:typedef
  setLoginEmmit(isLogin: boolean) {
    return this.login.next(isLogin);
  }


  // tslint:disable-next-line:typedef
  setCountEmit(isCount: boolean) {
    return this.count.next(isCount);

  }
  /*userDetailsEmmit(userData: any) {
    return this.user.next(userData);
  }*/

  // tslint:disable-next-line:typedef
  setLoaderEmmit(isLoading: boolean) {
    return this.loading.next(isLoading);
  }

  // ALTERNATIVE
  async setTempEmmit(isTempData: any): Promise<any> {
    return await this.isTemp.next(isTempData);
  }
}
