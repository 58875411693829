<ng-container *ngIf="(isLoading | async) && !isButton && loading && !customLoading">
  <div class="loader {{class}}" [ngStyle]="{'background-color': background}" [class.full]="fullPage">
    <p [ngStyle]="{'color': color}">{{text}}</p>
    <div class="bounce1" [ngStyle]="{'background-color': color}"></div>
    <div class="bounce2" [ngStyle]="{'background-color': color}"></div>
    <div class="bounce3" [ngStyle]="{'background-color': color}"></div>
  </div>
</ng-container>

<ng-container *ngIf="!(isLoading | async) && isButton && !customLoading">
  {{buttonText}}
</ng-container>

<ng-container *ngIf="(isLoading | async) && isButton && !customLoading">
  <div class="loader {{class}}" [ngStyle]="{'background-color': background}">
    <p [ngStyle]="{'color': color}">{{text}}</p>
    <div class="bounce1" [ngStyle]="{'background-color': color}"></div>
    <div class="bounce2" [ngStyle]="{'background-color': color}"></div>
    <div class="bounce3" [ngStyle]="{'background-color': color}"></div>
  </div>
</ng-container>

<!-- <ng-container *ngIf="customLoading">
  <div class="loader {{class}}" [ngStyle]="{'background-color': background}" [class.full]="fullPage">
    <p [ngStyle]="{'color': color}">{{text}}</p>
    <div class="bounce1" [ngStyle]="{'background-color': color}"></div>
    <div class="bounce2" [ngStyle]="{'background-color': color}"></div>
    <div class="bounce3" [ngStyle]="{'background-color': color}"></div>
  </div>
</ng-container> -->
