import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<MessageComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any, private router: Router) { }

  ngOnInit(): void {
    console.log(this.dialogData);



  }

  // tslint:disable-next-line:typedef
  goToLogin(type: any) {
    if (type === 'login') {
      this.router.navigateByUrl('/login');

    } else {
      this.router.navigateByUrl('/register');
      // this.dialogRef.close({ success: true });
    }
    this.dialogRef.close({ success: true });
  }

  // tslint:disable-next-line:typedef
  close() {
    this.dialogRef.close({ success: true });

  }
}
