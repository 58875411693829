<div class="modal-sec">
  <div class="cancel-icon">
    <button>
      <mat-icon class="cancel" (click)="close()">cancel</mat-icon>
    </button>
  </div>


  <ng-container *ngIf="dialogData === 'message'">
    <p class="modal-text">Please login or create a login account to complete your purchase.</p>

    <div class="row">
      <div class="col-md-6 new-button ">
        <button (click)="goToLogin('login')">Login</button></div>
      <div class="col-md-6 new-button ">
        <button (click)="goToLogin('reg')">New? Create a free account</button></div>


    </div>


  </ng-container>
</div>
