import { Component, HostListener, OnInit } from '@angular/core';
import { StorageService } from '@project/services/storage.service';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/event.service';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
// import * as moment from 'moment';
import * as moment from 'moment-timezone';
import { ApiService } from '../../services/api.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuBackDrop: any;
  mainMenuWrap: any;
  header: any;
  sticky: any;
  loggedInUser = false;
  userData: any;
  acronym: any;
  // now: Date = new Date();
  // timeZone : any;
  // apiService: any;
  eventData: any;
  count: any;

  constructor(
    private storage: StorageService,
    private router: Router,
    private event: EventService,
    private socialAuthService: SocialAuthService,
    private apiService: ApiService
  ) {

    this.event.isCount.subscribe((res: any) => {
      console.log('36', res);
      if (res) {
        this.getCount();
      }

    });
  }

  ngOnInit(): void {
    /*setInterval(() => {
      this.now = new Date();
      this.timeZone = moment.tz(moment.tz.guess()).zoneAbbr()
    }, 1);*/


    this.header = document.getElementById('myHeader');
    this.sticky = this.header.offsetTop + 210;
    this.event.isLogin.subscribe(res => {
      console.log(res);

      if (res) {
        this.userData = this.storage.getUser();
        console.log('59', this.userData);

        this.checkIsLogin();
      }
    });
    this.checkIsLogin();
    this.promotedEvent();
    this.getCount();
  }
  goToCart() {
    if (this.userData !== undefined) {
      this.router.navigateByUrl('/add-to-cart');
    } else {
      this.router.navigateByUrl('/login');

    }
  }

  getCount() {
    this.apiService.get('cart-count').subscribe((res: any) => {
      // console.log(res);
      if (res.status) {
        this.count = res.data;

      } else {
        this.count = 0;
      }
      console.log(this.count);

    });
  }


  signUpNow() {
    const signUpNow = document.getElementById('signUpNow');
    signUpNow?.classList.add('d-block');
  }

  clrNowSignUp() {
    const signUpNow = document.getElementById('signUpNow');
    signUpNow?.classList.remove('d-block');
  }

  openMenu() {
    this.menuBackDrop = document.querySelector('.menuBackDrop');
    this.menuBackDrop.classList.add('active');
    this.mainMenuWrap = document.querySelector('.mainMenuWrap');
    this.mainMenuWrap.classList.add('active');
  }
  closeMenu() {
    this.menuBackDrop = document.querySelector('.menuBackDrop');
    this.menuBackDrop.classList.remove('active');
    this.mainMenuWrap = document.querySelector('.mainMenuWrap');
    this.mainMenuWrap.classList.remove('active');
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.myFunction();
  }

  myFunction() {
    if (window.pageYOffset > this.sticky) {
      this.header.classList.add('sticky');
    } else {
      this.header.classList.remove('sticky');
    }
  }

  /*checkIsLogin(): void {
    if (this.storage.getUser()?.token) {
      this.loggedInUser = true;
    }
  }*/

  checkIsLogin(): void {
    // this.userData = this.storage.getUser();
    // console.log('header userdata', this.userData);
    const matches = this.userData?.full_name?.match(/\b(\w)/g); // ['J','S','O','N']
    this.acronym = matches?.join(''); // JSON
    if (this.userData?.token) {
      this.loggedInUser = true;
    }
  }

  logout(): void {
    this.loggedInUser = false;
    this.event.setLoginEmmit(false);
    // let userLoginType = this.storage.getUser()?.loginType;
    // if (userLoginType === 'social') {
    //   this.socialAuthService.signOut();
    // }
    this.storage.clearUser();
    this.userData = null;
    this.router.navigate(['home']);
  }

  promotedEvent(): void {
    this.apiService.get(`promoted-event`).subscribe((res: any) => {
      if (res.status) {
        this.eventData = res?.data;
        // console.log(this.eventData);
      } else {
        this.eventData = {};
      }
    }, (err: Error) => {
      this.apiService.alert('Something went wrong', 'warning');
    });
  }

  regAUser(): void {
    const encEventId = this.storage.encription(this.eventData?.id, 'EVENT-ID').toString();
    this.router.navigate([`/event-registration`, encEventId]);
  }
}
