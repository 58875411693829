<section class="clientsWrap">
  <div class="container">
    <div class="row justify-content-center">
      <div class="fs-48">Our Happy Clients</div>

      <div class="w-100">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig1"
          (init)="slickInit($event)">

          <div ngxSlickItem class="slide" *ngFor="let item of testimonialList">
            <div class="client-item">
              <img [src]="item?.profile_image_full_path" alt="./assets/images/no-image-user.jpeg">
              <div>
                <p class="text-muted"><small>{{item?.statement}}</small></p>
                <p class="text-red">- {{item?.first_name}} {{item?.last_name}}</p>
              </div>
            </div>
          </div>

        </ngx-slick-carousel>
      </div>
    </div>
  </div>

  <section class="marketingWrap">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 left">
          <img src="./assets/images/mrktng1.png" alt="">
        </div>
        <div class="col-lg-6 content-md__center">
          <h1 class="text-center">Marketing Made Easy Branding Packages</h1>
          <div class="position-relative marketing-banner">
            <div class="fs-49 text-white">Starting <span>at only</span></div>
            <div class="fs-100">$499<sup>95</sup></div>
            <button class="btn btn-primary btn-c" [routerLink]="['/products']">Order Now</button>
            <img src="./assets/images/mrktng2.png" alt="" class="prwrap">
          </div>
        </div>
      </div>
    </div>
  </section>

</section>
