<!-- <mat-dialog-content class="mat-typography"> -->
<div style="position: relative;">
  <button class="btn-close" mat-dialog-close>
    <span class="material-icons">
      clear
    </span>
  </button>

  <div class="container" style="align-items: center;">
    <h2 style="text-align: center;">{{data?.name}}</h2>
    <div [innerHTML]="data?.terms_condition?.terms_condition"></div>
  </div>
</div>
<!-- </mat-dialog-content> -->
