<!-- <p>sample-paragraph works!</p> -->
<div>
  <div class="text-right">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
  </div>


  <h2 class="text-center">{{product_name}} Sample Paragraphs</h2>
  <ul *ngFor="let item of paraData; let i =index" #inputTarget>
    <li [innerHTML]="item?.brochure_sample_paras"></li>
    <button class="btn btn-primary" *ngIf="i !== index || !isCopy" ngxClipboard
      (click)="copyText(item?.brochure_sample_paras,i)">copy</button>
    <button class="btn btn-success" *ngIf="i === index && isCopy">Copied</button>

  </ul>
</div>
